//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchMemberLevelRecords, fetchMemberList, fetchMemberLevels, addMemberLevelRecord } from "@/api/member";
import { fetchPropertyList } from "@/api/charge";
import "@/utils/global.js";
import Pagination from "@/components/Pagination";
export default {
  name: "MemberLevelRecord",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        start_time: "",
        end_time: "",
        keyword: "",
        mobile: '',
        filter: {
          push_status: ""
        }
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      btnLoading: false,
      total_num: 0,
      total: 0,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      levelDialog: false,
      propertyLoading: false,
      propertyOptions: [],
      memberLoading: false,
      memberOptions: [],
      levelLoading: false,
      levelOptions: [],
      dialogForm: {
        member_id: '',
        level_id: '',
        property_id: '',
        is_forever: '0',
        valid_time: '',
        mark: ''
      },
      rules: {
        property_id: [{
          required: true,
          message: "请选择小区",
          trigger: "blur"
        }],
        member_id: [{
          required: true,
          message: "请选择用户",
          trigger: "blur"
        }],
        level_id: [{
          required: true,
          message: '请选择会员等级',
          trigger: "blur"
        }],
        mark: [{
          required: true,
          message: "备注必填",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    if (this.$route.query && this.$route.query.mobile) {
      this.searchForm['mobile'] = this.$route.query.mobile;
    }

    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchMemberLevelRecords(data).then(function (response) {
        console.log(response);
        _this.total_num = response.meta.total_num;
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.total = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "error",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleTime: function handleTime(val) {
      if (val) {
        this.searchForm["start_time"] = val[0];
        this.searchForm["end_time"] = val[1];
      } else {
        this.searchForm["start_time"] = "";
        this.searchForm["end_time"] = "";
      }
    },
    resetForm: function resetForm(formName) {
      this.searchForm["start_time"] = "";
      this.searchForm["end_time"] = "";
      this.$refs[formName].resetFields();
    },
    resetLevel: function resetLevel() {
      this.dialogForm = {
        member_id: '',
        level_id: '',
        property_id: '',
        is_forever: '0',
        valid_time: '',
        mark: ''
      };
    },
    handleAddBtn: function handleAddBtn() {
      this.resetLevel();
      this.levelTitle = "create";
      this.levelDialog = true;
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.$refs["dialogForm"].validate(function (valid) {
        if (valid) {
          _this2.btnLoading = true;
          addMemberLevelRecord(_this2.dialogForm).then(function (res) {
            _this2.$message({
              type: "success",
              message: "添加成功"
            });

            _this2.levelDialog = false;
            _this2.btnLoading = false;

            _this2.getTableData();
          }).catch(function (error) {
            console.log(error);
            _this2.btnLoading = false;

            _this2.$message({
              type: "error",
              message: error.data["message"]
            });
          });
        }
      });
    },
    //搜索小区
    remoteProperty: function remoteProperty(query) {
      var _this3 = this;

      if (query !== "") {
        this.propertyLoading = true;
        setTimeout(function () {
          _this3.propertyLoading = false;
          var data = {
            keyword: query
          };
          fetchPropertyList(data).then(function (res) {
            _this3.propertyOptions = res.data;
          });
        }, 100);
      } else {
        this.propertyOptions = [];
      }
    },
    //搜索用户
    remoteMember: function remoteMember(query) {
      var _this4 = this;

      if (query !== "") {
        this.memberLoading = true;
        setTimeout(function () {
          _this4.memberLoading = false;
          var data = {
            keyword: query
          };
          fetchMemberList(data).then(function (res) {
            _this4.memberOptions = res.data;
          });
        }, 100);
      } else {
        this.memberOptions = [];
      }
    },
    //搜索会员等级
    remoteLevel: function remoteLevel(query) {
      var _this5 = this;

      if (query !== "") {
        this.levelLoading = true;
        setTimeout(function () {
          _this5.levelLoading = false;
          var data = {
            keyword: query
          };
          fetchMemberLevels(data).then(function (res) {
            _this5.levelOptions = res.data;
          });
        }, 100);
      } else {
        this.levelOptions = [];
      }
    }
  }
};